import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
    Github, Linkedin, MessageSquare, Mail, Code, Rocket, Target, 
    BicepsFlexed, CircleCheckBig, Workflow, ChevronDown, ChevronUp, 
    ExternalLink 
} from 'lucide-react';

// Import Images
import Profile_pic from '../images/Safwanashraf_pic.jpeg';
import Ecommerce_pic from '../images/Ecommerce_pic.png';
import Oidrob_pic from '../images/Oidrob_pic.png';
import ToDo_pic from '../images/ToDo_pic.png';
import SpanishDelight_pic from '../images/SpanishDelight1_pic.png';

// Import Components
import CreativeTechBackground from './CreativeTechBackground';
import MemoryGame from './MemoryGame';

// Project Data
const projects = [
    {
        id: 'oidrob',
        title: "Oidrob",
        description: "Oidrob is an innovative online tool for managing tasks and projects with real-time collaboration. Stay organized, track deadlines, and boost productivity in one seamless platform.",
        technologies: ["React.js", "Tailwind CSS", "Node.js", "Socket.io", "Git"],
        githubLink: "https://github.com/Safwanashraf/Oidrob",
        demoLink: "#",
        image: Oidrob_pic,
        fullDescription: `Oidrob is a comprehensive task management platform designed to revolutionize team collaboration. 
        
        Key Features:
        - Real-time task tracking
        - Collaborative workspace
        - Intuitive user interface
        - Advanced project management`
    },
    {
        id: 'ecommerce',
        title: "E-commerce Project",
        description: "A full-stack E-commerce application built with React.js for the frontend and Node.js with Express for the backend.",
        technologies: ["React.js", "Node.js", "Express.js", "MongoDB", "JWT"],
        githubLink: "https://github.com/Safwanashraf/MERNCart",
        demoLink: "#",
        image: Ecommerce_pic,
        fullDescription: `Full-stack E-commerce platform with comprehensive features.
        
        Core Functionalities:
        - User Authentication
        - Product Catalog
        - Shopping Cart
        - Payment Integration`
    },
    // Add other projects similarly
];

const Portfolio = () => {
    const [isFullBioExpanded, setIsFullBioExpanded] = useState(false);

    return (
        <div className="relative min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-black text-white overflow-x-hidden">
            <CreativeTechBackground />
            <div className="container mx-auto px-4 md:px-6 lg:px-8 py-8 md:py-12 lg:py-16 relative z-10 max-w-screen-2xl">
                
                {/* Hero Section */}
                <motion.header 
                    initial={{ opacity: 0, y: -50 }}   
                    animate={{ opacity: 1, y: 0 }}   
                    transition={{ duration: 1 }}   
                    className="text-center mb-12 md:mb-16 lg:mb-20"
                >
                    <h1 className="text-4xl md:text-6xl lg:text-8xl font-black text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 mb-4">  
                        Safwan Ashraf  
                    </h1>
                </motion.header>

                {/* About Section */}
                <section className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 mb-16">  
                    <div className="bg-white/10 rounded-2xl p-6 md:p-8 lg:p-10 flex flex-col relative">  
                        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">  
                            About Me  
                        </h2>  
                        <div className="flex flex-col xl:flex-row items-center mb-6 space-y-4 xl:space-y-0 xl:space-x-8">  
                            <img   
                                src={Profile_pic}  
                                alt="Safwan Ashraf"  
                                className="w-32 h-32 md:w-48 md:h-48 lg:w-56 lg:h-56 rounded-full object-cover border-4 border-purple-500 shrink-0"   
                            />  
                            <p className={`text-sm md:text-base lg:text-lg text-gray-300 leading-relaxed text-center md:text-left 
                                ${!isFullBioExpanded ? 'line-clamp-4' : ''}`}>  
                                    I'm not just a developer—I'm a visionary problem solver dedicated to innovation. 
                                    My passion lies in bridging the gap between technical solutions and business growth. 
                                    I don't just code; I strategize, analyze, and deliver impactful results.
                                {isFullBioExpanded && (
                                    <>
                                    By leveraging cutting-edge tools like AI, I enhance efficiency and ensure the highest quality outcomes. 
                                    My unique perspective combines technical expertise with business acumen, allowing me to connect development with sales, marketing, and beyond.
                                    </>
                                )}
                            </p>  
                        </div>
                        
                        <button 
                            onClick={() => setIsFullBioExpanded(!isFullBioExpanded)} 
                            className="flex items-center justify-center self-center bg-white/10 px-4 py-2 rounded-full hover:bg-white/20 transition-all"
                        >
                            {isFullBioExpanded ? 'Show Less' : 'Read More'}
                        </button>
                    </div>
                    
                    <MemoryGame />
                </section>

                {/* Projects Section */}
                <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6 md:gap-8 lg:gap-10 mb-16">
                    {projects.map((project) => (
                        <motion.div 
                            key={project.id} 
                            className="bg-white/10 rounded-2xl overflow-hidden relative group"
                            whileHover={{ scale: 1.05 }}
                        >
                            <img 
                                src={project.image} 
                                alt={project.title}
                                className="w-full h-48 sm:h-56 lg:h-64 object-cover"
                            />
                            <div className="p-4 md:p-6 lg:p-8">
                                <h3 className="text-xl md:text-2xl lg:text-3xl font-bold mb-2 md:mb-4 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
                                    {project.title}
                                </h3>
                                <p className="text-xs md:text-sm lg:text-base text-gray-300 mb-2 md:mb-4 line-clamp-3">
                                    {project.description}
                                </p>

                                <Link 
                                    to={`/projects/${project.id}`}
                                    className="flex items-center justify-center bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-full mt-4 transition-all"
                                >
                                    View Project Details <ExternalLink className="ml-2" size={16} />
                                </Link>
                            </div>
                        </motion.div>
                    ))}
                </section>
            </div>
        </div>
    );
};

export default Portfolio;
export { projects };