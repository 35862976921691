import React from 'react';  
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  
import Portfolio from './components/Portfolio';  
import ProjectDetail from './components/ProjectDetail';  
import './index.css';

function App() {  
  return (  
    <Router>  
      <Routes>  
        <Route path="/" element={<Portfolio />} />  
        <Route path="/projects/:id" element={<ProjectDetail />} />  
      </Routes>  
    </Router>  
  );  
}  

export default App;