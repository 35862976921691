import React, { useState, useEffect } from "react";  
import { motion } from 'framer-motion';  

// Number Guessing Game Component  
const NumberGuessingGame = () => {  
    const [targetNumber, setTargetNumber] = useState(null);  
    const [guess, setGuess] = useState('');  
    const [feedback, setFeedback] = useState('');  
    const [attempts, setAttempts] = useState(0);  
    const [gameOver, setGameOver] = useState(false);  

    useEffect(() => {  
        startNewGame();  
    }, []);  

    const startNewGame = () => {  
        const randomNumber = Math.floor(Math.random() * 100) + 1; // Random number between 1 and 100  
        setTargetNumber(randomNumber);  
        setGuess('');  
        setFeedback('');  
        setAttempts(0);  
        setGameOver(false);  
    };  

    const handleGuess = () => {  
        const numericGuess = parseInt(guess, 10);  
        if (isNaN(numericGuess) || numericGuess < 1 || numericGuess > 100) {  
            setFeedback("Please enter a valid number between 1 and 100.");  
            return;  
        }  

        setAttempts(attempts + 1);  

        if (numericGuess === targetNumber) {  
            setFeedback(`Congratulations! You guessed the number in ${attempts + 1} attempts!`);  
            setGameOver(true);  
        } else if (numericGuess < targetNumber) {  
            setFeedback("Too low! Try again.");  
        } else {  
            setFeedback("Too high! Try again.");  
        }  

        setGuess(''); // Clear input after each guess  
    };  

    const handleKeyPress = (event) => {  
        if (event.key === 'Enter') {  
            handleGuess();  
        }  
    };  

    return (  
        <div className="bg-white/10 rounded-2xl p-4 sm:p-6 text-center w-full">  
            <h2 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-4 text-white">   
                Number Guessing Game   
            </h2>  
            <p className="text-gray-300 mb-2 sm:mb-4">Guess a number between 1 and 100:</p>  
            <input  
                type="number"  
                value={guess}  
                onChange={(e) => setGuess(e.target.value)}  
                onKeyPress={handleKeyPress} // Listen for the Enter key  
                className="border-2 border-gray-600 rounded px-3 py-2 mb-4 w-1/2"  
                disabled={gameOver}  
                style={{ color: 'black' }}
                placeholder="Enter your guess" // Optional: Placeholder for clarity  
            />  
            <motion.button  
                onClick={handleGuess}  
                className="mt-2 bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700 transition"  
                whileHover={{ scale: 1.1 }}  
                whileTap={{ scale: 0.9 }}  
                disabled={gameOver}  
            >  
                Submit Guess  
            </motion.button>  
            {feedback && <p className="text-gray-300 mt-4">{feedback}</p>}  
            {gameOver && (  
                <div className="mt-4">  
                    <p className="text-green-400">Game Over! 🎉</p>  
                    <button  
                        onClick={startNewGame}  
                        className="mt-2 bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700 transition"  
                    >  
                        Start a New Game  
                    </button>  
                </div>  
            )}  
        </div>  
    );  
};  

export default NumberGuessingGame;