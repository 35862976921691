import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { projects } from './Portfoliotry';
import { Github, ExternalLink } from 'lucide-react';

const ProjectDetail = () => {
    const { id } = useParams();
    const project = projects.find(p => p.id === id);

    if (!project) return <div>Project Not Found</div>;

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-black text-white p-8">
            <div className="max-w-4xl mx-auto bg-white/10 rounded-2xl overflow-hidden">
                <img 
                    src={project.image} 
                    alt={project.title} 
                    className="w-full h-96 object-cover"
                />
                <div className="p-8">
                    <h1 className="text-4xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
                        {project.title}
                    </h1>
                    <p className="text-lg text-gray-300 mb-6">{project.description}</p>
                    
                    <div className="prose text-gray-300 mb-6">
                        {project.fullDescription}
                    </div>
                    
                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold mb-3 text-pink-400">Technologies Used</h2>
                        <div className="flex flex-wrap gap-2">
                            {project.technologies.map(tech => (
                                <span 
                                    key={tech} 
                                    className="bg-white/20 px-3 py-1 rounded-full text-sm"
                                >
                                    {tech}
                                </span>
                            ))}
                        </div>
                    </div>

                    <div className="flex space-x-4">
                        {project.githubLink !== '#' && (
                            <a 
                                href={project.githubLink} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="flex items-center bg-white/10 hover:bg-white/20 px-4 py-2 rounded-full transition-all"
                            >
                                <Github className="mr-2" /> GitHub Repository
                            </a>
                        )}
                        {project.demoLink !== '#' && (
                            <a 
                                href={project.demoLink} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="flex items-center bg-purple-600 hover:bg-purple-700 px-4 py-2 rounded-full transition-all"
                            >
                                <ExternalLink className="mr-2" /> Live Demo
                            </a>
                        )}
                    </div>

                    <Link 
                        to="/" 
                        className="mt-4 inline-block bg-gray-700 hover:bg-gray-600 px-4 py-2 rounded-full transition-all"
                    >
                        Back to Portfolio
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetail;